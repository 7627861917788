import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Image from 'next/image';

const useStyles = makeStyles()((theme) => ({
  container: {
    background: theme.palette.primary.light,
    color: 'white',
    height: '100%',
    padding: theme.spacing(7, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 3),
    },
  },
  logo: {
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1, 0, 0, 3),
    },
  },
}));

interface TextLeftSidebarProps {
  title: string;
  subtitle?: string;
  description: string;
}

const TextLeftContent: React.FC<React.PropsWithChildren<TextLeftSidebarProps>> = ({ title, subtitle, description }) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div className={classes.logo}>
          <Image src="/static/svg/jlive-logo/logo-light.svg" alt="logo" width={70} height={35} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{subtitle}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TextLeftContent;
