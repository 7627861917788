import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import Image from 'next/image';

interface IProps {
  isPhone?: boolean;
  left?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  bottom?: React.ReactNode;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    ...theme.mixins.scrollBarOverflowYAuto,
    height: '100%',
    background: 'white',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      overflowY: 'hidden',
      borderRadius: 8,
      borderTopLeftRadius: 100,
      maxHeight: 543,
    },
  },
  leftSidebar: {
    alignItems: 'center',
    alignContent: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: 433,
      minHeight: 548,
      overflow: 'hidden',
      borderBottomRightRadius: 100,
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      alignContent: 'flex-start',
    },
  },
  right: {
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 544,
      height: '100%',
    },
  },
  content: {
    height: '100%',
    padding: 40,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  scrollToViewMore: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.90) 50.5%, #FFF 100%)',
    height: 60,
    bottom: 124,
    zIndex: 110,
    position: 'sticky',
    transition: 'opacity 1s ease-in-out',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      bottom: 80,
      width: '100%',
    },
  },
  scrollToViewMoreImage: {
    display: 'inline-block',
    textAlign: 'center',
    width: 36,
    height: 36,
  },
  scrollToViewMoreTitle: {
    position: 'relative',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '160%',
    color: '#4E6DF5',
    height: 21,
    textAlign: 'center',
    width: '100%',
  },
  actions: {
    zIndex: 100,
    backgroundColor: '#fff',
    padding: '40px 40px',
    position: 'sticky',
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      width: '100%',
      padding: '16px 32px',
      bottom: 0,
    },
  },
  contentWrapper: {
    [theme.breakpoints.up('md')]: {
      minHeight: 543,
    },
  },
}));

const PassportVerifyLayout: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { isPhone, left, content, actions, bottom } = props;
  const { classes } = useStyles();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const [hasScroll, setScroll] = useState(false);

  useEffect(() => {
    const rootWrapper = document.getElementById('root-wrapper');
    setScroll(rootWrapper.scrollHeight - 5 > rootWrapper.clientHeight);
  }, [isMobile, isPhone]);

  return (
    <Grid id="root-wrapper" container className={classes.root} onScroll={() => setScroll(false)}>
      {!isMobile && (
        <Grid item xs={12} md className={classes.leftSidebar}>
          {left}
        </Grid>
      )}
      <Grid item xs={12} md className={classes.right} onScroll={() => setScroll(false)}>
        <Grid className={classes.contentWrapper} container justifyContent="space-between">
          <Grid item xs={12} className={classes.content}>
            {content}
          </Grid>
          {hasScroll && (
            <Grid id="scroll-for-more" item xs={12} className={classes.scrollToViewMore}>
              <Image
                className={classes.scrollToViewMoreImage}
                src="/static/img/ScrollForMore.png"
                alt=""
                width={36}
                height={36}
              />
              <div className={classes.scrollToViewMoreTitle}>Scroll down</div>
            </Grid>
          )}
          <Grid item xs={12} className={classes.actions}>
            {actions}
          </Grid>
        </Grid>
      </Grid>
      {isMobile && (
        <Grid item xs={12} md className={classes.leftSidebar}>
          {bottom}
        </Grid>
      )}
    </Grid>
  );
};

export default memo(PassportVerifyLayout);
