import { useTranslation } from 'i18n';
import Image from 'next/image';
import React, { useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PassportVerifyLayout from '../PassportVerifyLayout';
import TextImageLeftContent from '../../auth/TextImageLeftContent';
import PassportVerificationContent from '../PassportVerificationContent';
import UpdateProfileForm from './UpdateProfileForm';
import Button from '../../common/Button';
import { submitForm } from '../../../utils/form';

export interface ProfileUpdatesViewProps {
  user: any;
  isSending: boolean;
  onSubmit: (values: any) => void;
  stepper?: boolean;
}

const ProfileUpdatesView: React.FC<React.PropsWithChildren<ProfileUpdatesViewProps>> = (props) => {
  const { user, isSending, stepper, onSubmit } = props;
  const [t] = useTranslation('user');
  const formRef = useRef(null);

  const handleSubmitForm = () => submitForm(formRef, false);

  return (
    <PassportVerifyLayout
      left={
        <TextImageLeftContent
          title={t<string>('jLivePassport.phoneVerificationModal.leftSideTitle')}
          image={
            <Image
              src="/static/img/GetPassportModalIMG.png"
              alt="GetPassportModalIMG"
              width={332}
              height={366}
              priority
            />
          }
          centerTitle
          pdt={24}
        />
      }
      content={
        <PassportVerificationContent
          title={t<string>('jLivePassport.phoneVerificationModal.userFields.title')}
          mt={24}
          description={
            <div style={{ maxWidth: 416 }}>
              {t<string>('jLivePassport.phoneVerificationModal.userFields.description')}
            </div>
          }
          form={<UpdateProfileForm innerRef={formRef} user={user} onSubmit={onSubmit} isSending={isSending} />}
        />
      }
      actions={
        <Grid container justifyContent={stepper ? 'space-between' : 'end'} alignItems="center">
          {stepper && (
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {t<string>('jLivePassport.phoneVerificationModal.userFields.step')}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Box mt={1} textAlign="center">
              <Button
                style={{ height: 40 }}
                rounded
                color="primary"
                variant="contained"
                onClick={handleSubmitForm}
                disabled={isSending}
              >
                {t<string>('common:button.continue')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      }
    />
  );
};

export default ProfileUpdatesView;
