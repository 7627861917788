import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { capitalize } from '@mui/material/utils';
import Image from 'next/image';

interface IProps {
  logo?: boolean;
  text?: string;
  color: 'primary' | 'secondary';
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
  },
  colorPrimary: {
    background: theme.palette.primary.main,
  },
  colorSecondary: {
    background: theme.palette.secondary.main,
  },
  logoWrapper: {
    width: '60%',
    height: '100%',
    position: 'relative',
  },
  text: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '120%',
    textAlign: 'center',
    letterSpacing: '-0.02em',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
}));

const TextTile: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { logo, text, color = 'primary' } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, classes[`color${capitalize(color)}` as keyof typeof classes])}>
      {logo && !text && (
        <div className={classes.logoWrapper}>
          <Image objectFit="contain" src="/static/svg/jlive-logo/logo-light.svg" alt="logo" layout="fill" />
        </div>
      )}
      {text && !logo && <Typography className={classes.text}>{text}</Typography>}
    </div>
  );
};

export default TextTile;
