import React, { useRef, useState } from 'react';
import { useTranslation } from 'i18n';
import TextImageLeftContent from 'components/auth/TextImageLeftContent';
import Image from 'next/image';
import { Box, Grid, Typography } from '@mui/material';
import Button from 'components/common/Button';
import { submitForm } from 'utils/form';
import PhoneFieldForm from './PhoneFieldForm';
import PassportVerifyLayout from '../PassportVerifyLayout';
import PassportVerificationContent from '../PassportVerificationContent';
import VerificationCodeFieldForm from './VerificationFiledForm';
import TextImageBottomContent from '../../auth/TextImageBottomContent';

export interface PhoneVerificationViewProps {
  user: any;
  isSendingGetVerificationCode: boolean;
  isSuccessfullySubmittedGetVerificationCode: boolean;
  onSubmitGetVerificationCode: (values: any) => void;
  isSendingCheckVerificationCode: boolean;
  onSubmitCheckVerificationCode: (values: any) => void;
  resetPhone: () => void;
  resend: () => void;
  error?: any;
  stepper?: boolean;
}

const BUILD_ENV = process.env.NEXT_PUBLIC_BUILD_ENV;
const isProductionEnv = () => BUILD_ENV === 'production';

const PhoneVerificationView: React.FC<React.PropsWithChildren<PhoneVerificationViewProps>> = (props) => {
  const {
    user,
    isSendingGetVerificationCode,
    isSuccessfullySubmittedGetVerificationCode,
    onSubmitGetVerificationCode,
    isSendingCheckVerificationCode,
    onSubmitCheckVerificationCode,
    resetPhone,
    resend,
    error,
    stepper,
  } = props;
  const [t] = useTranslation('user');
  const phoneVerified = isSuccessfullySubmittedGetVerificationCode;
  const [isResending, setIsResending] = useState(false);
  const formRefPhone = useRef(null);
  const formRefCode = useRef(null);

  const handleResetPhone = () => {
    setIsResending(false);
    resetPhone();
  };

  const handleResend = () => {
    setIsResending(true);
    resend();
  };

  const handleSubmitForm = () => {
    if (!phoneVerified && !isResending) return submitForm(formRefPhone, false);
    return submitForm(formRefCode, false);
  };

  return (
    <PassportVerifyLayout
      isPhone={!phoneVerified && !isResending}
      left={
        <TextImageLeftContent
          title={t<string>('jLivePassport.phoneVerificationModal.leftSideTitle')}
          image={
            <Image
              src="/static/img/GetPassportModalIMG.png"
              alt="GetPassportModalIMG"
              width={266}
              height={281}
              priority
            />
          }
          pdt={24}
          descriptionList={[
            t('jLivePassport.phoneVerificationModal.leftSideDescription.access'),
            t('jLivePassport.phoneVerificationModal.leftSideDescription.free'),
            t('jLivePassport.phoneVerificationModal.leftSideDescription.verify'),
          ]}
        />
      }
      content={
        !phoneVerified && !isResending ? (
          <PassportVerificationContent
            title={t<string>('jLivePassport.phoneVerificationModal.phone.title')}
            description={
              <div style={{ maxWidth: 416 }}>{t<string>('jLivePassport.phoneVerificationModal.phone.description')}</div>
            }
            form={
              <PhoneFieldForm
                innerRef={formRefPhone}
                isSending={isSendingGetVerificationCode}
                isProduction={isProductionEnv()}
                user={user}
                onSubmit={onSubmitGetVerificationCode}
                error={error}
              />
            }
          />
        ) : (
          <PassportVerificationContent
            title={t<string>('jLivePassport.phoneVerificationModal.code.title')}
            description={
              <div style={{ maxWidth: 416 }}>{t<string>('jLivePassport.phoneVerificationModal.code.description')}</div>
            }
            form={
              <VerificationCodeFieldForm
                innerRef={formRefCode}
                onSubmit={onSubmitCheckVerificationCode}
                isSending={isSendingCheckVerificationCode}
                onResend={handleResend}
                onResetPhone={handleResetPhone}
                error={error}
              />
            }
          />
        )
      }
      actions={
        <Grid container justifyContent={stepper ? 'space-between' : 'end'} alignItems="center">
          {stepper && (
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {t<string>('jLivePassport.phoneVerificationModal.phone.step')}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Box mt={1} textAlign="center">
              <Button
                style={{ height: 40 }}
                rounded
                onClick={handleSubmitForm}
                color="primary"
                variant="contained"
                disabled={isSendingGetVerificationCode || isSendingCheckVerificationCode}
              >
                {t<string>('common:button.continue')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      }
      bottom={
        <TextImageBottomContent
          title={t<string>('jLivePassport.phoneVerificationModal.leftSideTitle')}
          image={
            <Image
              src="/static/img/GetPassportModalIMG.png"
              alt="GetPassportModalIMG"
              width={270}
              height={308}
              priority
            />
          }
          centerTitle
          descriptionList={[
            t('jLivePassport.phoneVerificationModal.leftSideDescription.access'),
            t('jLivePassport.phoneVerificationModal.leftSideDescription.free'),
            t('jLivePassport.phoneVerificationModal.leftSideDescription.verify'),
          ]}
        />
      }
    />
  );
};

export default PhoneVerificationView;
