import React, { useState } from 'react';
import { ButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { capitalize } from '@mui/material/utils';
import NextImage from 'next/image';

const useStyles = makeStyles()((theme) => ({
  container: {
    textAlign: 'left',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  objectFitCover: {
    objectFit: 'cover',
  },
  objectFitContain: {
    objectFit: 'contain',
  },
  objectPositionTop: {
    objectPosition: 'top',
  },
  objectPositionBottom: {
    objectPosition: 'bottom',
  },
  objectPositionLeft: {
    objectPosition: 'left',
  },
  objectPositionRight: {
    objectPosition: 'right',
  },
  objectPositionCenter: {
    objectPosition: 'center',
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: theme.palette.custom.gray.extraLight,
  },
  variantSquare: {
    paddingBottom: '100%',
  },
  variantRectangle: {
    display: 'flex !important',
    paddingBottom: '62.5%',
  },
  variantWide: {
    display: 'flex !important',
    position: 'relative',
    paddingBottom: '22.226%',
  },
  variantAspectRatio: {
    display: 'flex !important',
  },
  rounded: {
    borderRadius: '8px',
    overflow: 'hidden',
  },
}));

const useButtonBaseStyles = makeStyles()({
  root: {
    display: 'block',
  },
});

interface IImageProps {
  placeholder?: React.ReactNode;
  src?: string;
  alt?: string;
  className?: string;
  objectFit?: string;
  objectPosition?: string;
  rounded?: boolean;
  variant?: 'rectangle' | 'square' | 'wide' | 'aspectRatio';
  priority?: boolean;
}

interface IClickableImageProps extends IImageProps {
  onClick: () => void;
}

interface IImageContainerProps extends IImageProps {
  onClick?: () => void;
}

const Image: React.FC<React.PropsWithChildren<IImageProps>> = ({
  placeholder,
  src,
  alt,
  className,
  objectFit = 'cover',
  objectPosition = 'center',
  rounded,
  variant = 'square',
  priority = false,
}) => {
  const { classes, cx } = useStyles();
  const [paddingBottom, setPaddingBottom] = useState('0');
  return (
    <div
      className={cx(classes.container, className, classes[`variant${capitalize(variant)}` as keyof typeof classes], {
        [classes.rounded]: rounded,
      })}
      style={variant === 'aspectRatio' ? { paddingBottom } : {}}
    >
      {!src && placeholder ? (
        <div className={classes.placeholder}>{placeholder}</div>
      ) : (
        <NextImage
          className={cx(
            classes.image,
            classes[`objectFit${capitalize(objectFit)}` as keyof typeof classes],
            classes[`objectPosition${capitalize(objectPosition)}` as keyof typeof classes],
          )}
          layout="fill"
          onLoad={({ target }) => {
            const { naturalWidth, naturalHeight } = target as HTMLImageElement;
            setPaddingBottom(`calc(100% / (${naturalWidth} / ${naturalHeight})`);
          }}
          src={src}
          alt={alt}
          priority={priority}
        />
      )}
    </div>
  );
};

const ClickableImage: React.FC<React.PropsWithChildren<IClickableImageProps>> = ({ onClick, ...props }) => {
  const { classes } = useButtonBaseStyles();
  return (
    <ButtonBase disableRipple classes={classes} onClick={onClick} component="a">
      <Image {...props} />
    </ButtonBase>
  );
};

const ImageContainer: React.FC<React.PropsWithChildren<IImageContainerProps>> = ({ onClick, ...props }) => {
  if (!props?.src && !props?.placeholder) return <React.Fragment key="no-image" />;
  if (onClick) return <ClickableImage onClick={onClick} {...props} />;
  return <Image {...props} />;
};

export default React.memo(ImageContainer);
